import React, { useState } from 'react';
import {
	IconButton,
	Typography,
	Divider,
	Button,
	Grid,
	Box,
	RadioGroup,
	FormControlLabel,
	Radio,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import { FilterAlt as FilterAltIcon, Close as CloseIcon } from '@mui/icons-material';
import Popper, { PopperPlacementType } from '@mui/material/Popper';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import { useLocationQuery, useLocationQuerySet } from 'app/shared/util/useLocationQuery';
import { FormSelectField } from 'app/shared/atoms';
import { UsersMultiSelect } from 'app/shared/molecules';

interface IFilterProps {
	callback?: any;
	sessionPackages?: any;
}

const ButtonWrapper = styled('div')`
	display: flex;
	justify-content: flex-end;
`;

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};

const CreditFilterDialog: React.FC<IFilterProps> = ({ callback, sessionPackages }) => {
	const searchParams = useLocationQuery();
	const setQuery = useLocationQuerySet();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { t } = useTranslation();
	const fields = ['filter_user', 'filter_packageid', 'filter_status']; // Sample fields keys

	const [open, setOpen] = React.useState(false);
	const [placement] = React.useState<PopperPlacementType>();

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
		setOpen((prev) => !prev);
	};

	const handleReset = (resetForm: any) => (e: any) => {
		resetForm();

		const filteredSearchParamsObject = _.omit(searchParams, fields);
		setQuery({ ...filteredSearchParamsObject, page: 1 });
		callback();
	};

	const handleApply = () => {
		handleClose();
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(false);
	};

	return (
		<>
			<Button
				variant="outlined"
				onClick={handleClick}
				style={{
					cursor: 'pointer',
					borderRadius: '4px',
					border: '1px solid #F2F2F2',
					color: '#727272',
				}}
			>
				<FilterAltIcon style={{ marginRight: '8px', color: '#727272' }} />
				<Typography>{t('filter')}</Typography>
			</Button>

			<Popper
				sx={{ zIndex: 1200, minWidth: '600px', maxWidth: '600px' }}
				open={open}
				anchorEl={anchorEl}
				placement={placement}
				transition
			>
				{({ TransitionProps }) => (
					<Fade {...TransitionProps} timeout={350}>
						<Paper style={{ padding: '30px' }}>
							<Grid container justifyContent={'space-between'}>
								<Grid>
									<Typography variant="h6" style={{ flex: 1 }}>
										{t('filterBy')}
									</Typography>
								</Grid>
								<Grid>
									<IconButton
										onClick={handleClose}
									>
										<CloseIcon />
									</IconButton>
								</Grid>
							</Grid>

							<Divider style={{ marginTop: '1rem' }} />

							<Formik
								initialValues={{
									...searchParams,
									//users: searchParams?.users?.split(',') || [],
								}}
								onSubmit={(values, actions) => {
									let param = { ...searchParams, ...values };
									if (!values.filter_user) {
										delete param.filter_user;
									}

									setQuery({ ...param});
									callback({ ...param});
								}}
								enableReinitialize
							>
								{({ values, setFieldValue, resetForm }) => {
									return (
										<>
											<Form>
												<Box style={{ marginTop: '15px' }}>
													<UsersMultiSelect
														label={`${t('employeeNames')}`}
														value={
															values?.filter_user
																? values?.filter_user
																: []
														} // Pass the value from Formik's state
														onChange={(users: any) => {
															setFieldValue('filter_user', users);
														}}
														isShowAdminUsers={false}
													/>
												</Box>
												<Box style={{ marginTop: '15px' }}>
													<FormSelectField
														label={`${t('productNames')}`}
														name="filter_packageid"
														options={sessionPackages?.map(
															(x: any) =>
																(x = {
																	value: x?._id || x?.id,
																	label: x?.name,
																})
														)}
														onChange={(data: any) => {
															console.log('dadaa', data);
														}}
													/>
												</Box>
												<Box
													style={{
														marginTop: '15px',
														display: 'flex',
														justifyContent: 'space-between',
														alignItems: 'center',
													}}
												>
													<Typography variant="caption" align="left">
														{`${t('activeCodes')}`}
													</Typography>
													<RadioGroup
														row
														name="filter_status"
														onChange={(e: any) => {
															setFieldValue(
																'filter_status',
																e.target.value
															);
														}}
														value={values?.filter_status}
													>
														<FormControlLabel
															value={'active'}
															control={<Radio />}
															label={`${t('Yes')}`}
														/>
														<FormControlLabel
															value={'inactive'}
															control={<Radio />}
															label={`${t('No')}`}
														/>
													</RadioGroup>
												</Box>
												<Grid
													container
													justifyContent={'flex-end'}
													alignItems={'center'}
													mt={'2rem'}
												>
													<Button
														onClick={handleReset(resetForm)}
														style={{
															marginRight: '1rem',
															color: '#EB5757',
															fontWeight: 600,
															textTransform: 'capitalize',
														}}
													>
														{t('resetFilters')}
													</Button>
													<ButtonWrapper>
														<Button
															variant="contained"
															color="primary"
															onClick={handleApply}
															style={brownButtonStyle}
															className="capitalize"
															type="submit"
														>
															{t('applyFilters')}
														</Button>
													</ButtonWrapper>
												</Grid>
											</Form>{' '}
										</>
									);
								}}
							</Formik>
						</Paper>
					</Fade>
				)}
			</Popper>
		</>
	);
};

export default CreditFilterDialog;
