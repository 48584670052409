/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import {
	Modal,
	Box,
	Button,
	Divider,
	Paper,
	Slide,
	Grid,
	Typography,
	IconButton,
	FormControl,
} from '@mui/material';

import { Form, Formik, FormikProps } from 'formik';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

import { AppDispatch } from '../../../redux/store';
import { defaultOrganisationValue } from '../../../model/organisation-model';
import { FormTextField, FormSelectField } from 'app/shared/atoms';
import {
	createOrganisation,
	updateOrganisation,
	fetchOrganisationsList,
} from '../organisationsApiService';
import COUNTRY_CODES from 'app/shared/data/CountryCodes.json';
import { AddOrganisationValidationSchema } from 'app/shared/validations/OrganisationValidationSchema';
import ConfirmDialog from 'app/shared/components/ConfirmDialog';
import { extractDialCode } from 'app/shared/components/CountryCodeExtractor';
import getCountryNameByDialCode from 'app/shared/components/CountryNameExtractor';

interface ModalFormProps {
	open: boolean;
	onClose: () => void;
	formData?: any;
	callback?: any;
	action?: any;
}

const brownButtonStyle = {
	backgroundColor: '#333333',
	color: 'white', // Text color
};

const BoldTypography = styled(Typography)(({ theme }) => ({
	fontWeight: 'bold', // Set the font weight to bold
}));

const AddOrganisationModalForm: React.FC<ModalFormProps> = ({
	open,
	onClose,
	formData,
	callback,
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch<AppDispatch>();
	const formikRef = useRef<FormikProps<any>>(null);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const [editData, setEditData] = useState<any>();

	// Set all the form data used for building the request object to the API finally
	useEffect(() => {
		if (formData?._id) {
			setEditData({
				...formData,
        countryCode: getCountryNameByDialCode(formData?.countryCode),
			});
		}
	}, [formData]);

	/**
	 * Handle Next on button click to load the next step if second page call the api
	 *
	 * @function
	 * @returns void
	 */
	const handleSubmit = async (values?: any, actions?: any) => {
		if (formData?._id) {
			delete values._id;

			dispatch(
				await updateOrganisation(formData?._id, { ...values }, () => {
					if (typeof callback === 'function') {
						callback();
					}
					onClose();
				})
			);
		} else {
			dispatch(
				await createOrganisation({ ...values }, () => {
					onClose();
					dispatch(fetchOrganisationsList({ page: 1 }));
				})
			);
		}

		actions.setSubmitting(false);
	};

	/**
	 * Handle close on button click
	 *
	 * @function
	 * @returns void
	 */
	const handleCloseModal = (event?: any, reason?: any) => {
		if (formikRef?.current?.dirty) {
			// Show confirmation dialog if there are unsaved changes
			setConfirmOpen(true);
		} else {
			onClose();
		}
	};

	// Handle close confirmation dialog
	const handleCancelClose = () => {
		setConfirmOpen(false);
	};

	// Handle confirm close action
	const handleConfirmClose = () => {
		setConfirmOpen(false);
		onClose();
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleCloseModal}
				closeAfterTransition
				slotProps={{
					backdrop: {
						style: {
							backgroundColor: 'rgba(0,0,0,0.5)',
						},
					},
				}}
			>
				<Slide in={open} direction="left">
					<Paper
						style={{
							position: 'absolute',
							right: '0',
							transform: 'translateY(-50%)',
							width: '35%',
							padding: '25px',
							height: '100vh',
							overflow: 'auto',
						}}
					>
						<Box>
							{/* Content of the sliding modal */}
							<BoldTypography variant="h6" style={{ marginBottom: '1rem' }}>
								{formData?._id ? t('editOrganisation') : t('addNewOrganisation')}
							</BoldTypography>
							<Divider />
							<IconButton
								edge="end"
								color="inherit"
								onClick={handleCloseModal}
								aria-label="close"
								sx={{
									position: 'absolute',
									top: '15px',
									right: '24px',
									cursor: 'pointer',
								}}
							>
								<CloseIcon />
							</IconButton>
							<div>
								<Formik
									innerRef={formikRef}
									initialValues={
										formData?._id ? editData : defaultOrganisationValue
									}
									onSubmit={(values, actions) => {
										handleSubmit(
											{
												...values,
												countryCode: extractDialCode(values?.countryCode),
											},
											actions
										);
									}}
									enableReinitialize
									validationSchema={AddOrganisationValidationSchema()}
								>
									{({ values, errors, setFieldValue }: any) => {
										return (
											<>
												<Form>
													<Grid
														container
														spacing={2}
														style={{ marginTop: '10px' }}
													>
														{' '}
														<Grid item xs={12}>
															<FormTextField
																name="name"
																label={t('organisationName')}
																placeholder={t(
																	'enterNameofOrganisation'
																)}
																fullWidth
																disabled={formData?._id}
															/>
														</Grid>
														<Grid item xs={12}>
															<FormTextField
																name="contactName"
																label={t('contactPerson')}
																placeholder={t('enterName')}
																fullWidth
															/>
														</Grid>
														<Grid item xs={12}>
															<FormTextField
																name="email"
																label={t('email')}
																placeholder={t('enterEmail')}
																fullWidth
															/>
														</Grid>
														<Grid item xs={12}>
															<Grid container spacing={2}>
																<Grid
																	item
																	xs={5}
																	style={{ marginTop: '18px' }}
																>
																	<FormSelectField
																		label={t('phone')}
																		name="countryCode"
																		options={COUNTRY_CODES?.map(
																			(x: any) =>
																				(x = {
																					...x,
																					label: x?.name,
																					value: x?.name,
																				})
																		)}
																		value={values?.countryCode}
																		defaultValue={
																			values?.countryCode
																		}
																		customWidth="240"
																	/>
																</Grid>
																<Grid item xs={7}>
																	<FormControl
																		fullWidth
																		sx={{ marginTop: '3.4rem' }}
																	>
																		<FormTextField name="phone" />
																	</FormControl>
																</Grid>
															</Grid>
														</Grid>
													</Grid>

													<div
														style={{
															display: 'flex',
															justifyContent: 'space-between',
														}}
													>
														<Grid
															container
															spacing={2}
															style={{ marginTop: '15px' }}
														>
															<Grid
																item
																xs={12}
																container
																justifyContent="flex-end"
															>
																<Button
																	variant="contained"
																	color="primary"
																	style={brownButtonStyle}
																	type="submit"
																>
																	{formData?._id
																		? t('saveChanges')
																		: t('saveNewOrganisation')}
																</Button>
															</Grid>
														</Grid>
													</div>
												</Form>
											</>
										);
									}}
								</Formik>
							</div>
						</Box>
					</Paper>
				</Slide>
			</Modal>

			{/* Confirmation dialog for unsaved changes */}
			<ConfirmDialog
				open={confirmOpen}
				onClose={handleCancelClose}
				onConfirm={handleConfirmClose}
			/>
		</>
	);
};

export default AddOrganisationModalForm;
