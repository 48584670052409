/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Modal,
  Box,
  Button,
  Divider,
  Paper,
  Slide,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { sortBy } from "lodash";

import CustomStepper from "../../../shared/components/CustomStepper";
import ConfirmDialog from "app/shared/components/ConfirmDialog"; 
import {
  generateTimeSlots,
  generateIntermediateTimeSlots,
} from "../../../shared/util/GenerateTimeSlots";
import GeneralInformation from "./Steps/GeneralInformation";
import Availability from "./Steps/Availability";
import { AppDispatch } from "../../../redux/store";
import { defaultTournamentValue } from "../../../model/tournament-model";
import { AddTournamentValidationSchema } from "../../../shared/validations/TournamentValidationSchema";

import "react-toastify/dist/ReactToastify.css";
import { ActionTypes } from "../../../config/constants";
import { fetchClubsList } from "../../Clubs/clubsApiService";
import {
  createTournament,
  fetchTournamentsList,
  updateTournament,
} from "../tournamentsApiService";
import { SubmitButton } from "app/shared/molecules";

interface ModalFormProps {
  open: boolean;
  onClose: () => void;
  action?: any;
  formData?: any;
  page?: any;
  refreshClubsGrid?: any;
  refresh?: any;
  setRefresh?: any;
  callback?: any;
  isOverviewPage?: any;
  refreshGrid?: any;
}

const BoldTypography = styled(Typography)(({ theme }) => ({
  fontWeight: "bold", // Set the font weight to bold
}));

const AddTournamentModalForm: React.FC<ModalFormProps> = ({
  open,
  onClose,
  action,
  formData,
  page,
  callback,
  isOverviewPage,
  refreshGrid
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [activeStep, setActiveStep] = useState(0); // Step active step
  // Create a reference for the Formik instance
  const formikRef = useRef<FormikProps<any>>(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  // Each form data default value created through the data models
  const [editData, setEditData] = useState<any>();
  const steps = [t("generalInformation"), t("availability")];

  const clubsData: any = useSelector(
    (state: RootState) => state?.clubs?.clubsData
  );

  useEffect(() => {
    let isMounted = true; // Variable to track component mount state

    if (open) {
      dispatch(fetchClubsList({ limit: 1000, page: 1 }));
    }

    return () => {
      // Cleanup function to unsubscribe when component unmounts
      // Check if the component is still mounted before dispatching
      if (isMounted) {
        // Add cleanup logic here if necessary
        // For example, cancelling any ongoing async operations
      }

      // Set isMounted to false when the component unmounts
      isMounted = false;
    };
  }, [open, dispatch]);

  // Set all the form data used for building the request object to the API finally
  useEffect(() => {
    if ((formData?._id || formData?.id) && action === ActionTypes?.EDIT) {
      setEditData({
        ...formData,
        ...formData.userDetail,
        countryCode: formData?.userDetail?.countryCode,
        clubs: formData?.club?._id,
        type: formData?.type,
        features: formData?.features,
        courts: formData?.courts,
      });
    }

    return () => {
      setEditData(null); // Reset editData when unmounting
    };
  }, [formData, action]);

  // Function to calculate slots based on the selected date and club's working hours
  const calculateSlots = (
    selectedDate: any,
    clubList: any,
    clubId: any,
    courtIndex: any
  ) => {
    const club = clubList?.find((club: any) => club._id === clubId);
    if (!club) return [];
    // Extract day of the week from the selected date
    // Get the day of the week as "Monday", "Tuesday", etc.
    const dayOfWeek = dayjs.utc(selectedDate)?.format("dddd")?.toLowerCase();

    // Find the working hours for the selected day
    const workingHours = club?.courts?.[courtIndex]?.workingHours?.find(
      (day: any) => day.day.toLowerCase() === dayOfWeek
    );

    if (!workingHours) return [];

    // Generate slots based on working hours
    return generateTimeSlots(workingHours.startTime, workingHours.endTime);
  };

  // Define a function to handle availability data update
  const handleAvailabilityChange = (updatedJSON: any) => {
    // Handle the availability data update here, e.g., update state
    //setAvailability(updatedJSON);
  };

  /**
   * Handle Next on button click to load the next step if second page call the api
   *
   * @function
   * @returns void
   */
  const handleNext = async (values?: any, actions?: any) => {
    if (activeStep === steps.length - 1) {
      const payload = {
        name: values?.name,
        // Add one day to startDate if it has changed
        startDate: dayjs.utc(values?.startDate),
        // Add one day to endDate if it has changed
        endDate: dayjs.utc(values?.endDate),
        // Add one day to registrationDeadline if it has changed
        registrationDeadline: dayjs.utc(values?.registrationDeadline),
        // isLevelRestriction: values?.isLevelRestriction,
        description: values?.description,
        type: values?.type, // Fill this with appropriate value
        gender: values?.gender,
        rulesAndRegulations: values?.rulesAndRegulations,
        attachments: values?.attachments,
        prizeDetails: values?.prizeDetails,
        // categoryLevels: values?.categoryLevels,
        isPublic: values?.isPublic,
        enrollmentType: values?.enrollmentType
          ? values?.enrollmentType
          : ["Doubles"],
        minTeams: values?.minTeams,
        maxTeams: values?.maxTeams,
        features: values?.features, // Fill this with appropriate value
        club: values?.clubs,
        cancellationPeriodInHours: values?.cancellationPeriodInHours,
        fee: values?.fee,
        // levels: values?.levels,
        courts:
          values?.courts?.map((x: any) => ({
            ...x,
            slots: generateIntermediateTimeSlots(x?.startTime, x?.endTime),
          })) || [],
      };

      if (formData?._id) {
        dispatch(
          updateTournament(formData?._id, { ...payload }, () => {
            if (typeof callback === "function") {
              callback();
            }
            onClose();
            actions.setSubmitting(false);
          })
        );
      } else {
        dispatch(
          await createTournament(payload, () => {
            setActiveStep(0);
            dispatch(fetchTournamentsList({ page: 1 }));
            if(refreshGrid)
              refreshGrid();
            onClose();
            actions.setSubmitting(false);
          })
        );
      }   
    } else {
      setActiveStep((prevStep) => prevStep + 1);
      actions.setSubmitting(false);
    }
  };

  /**
   * Handle back on button click to load the previous step
   *
   * @function
   * @returns void
   */
  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  /**
   * Handle close on button click
   *
   * @function
   * @returns void
   */
  const handleCloseModal = (event?: any, reason?: any) => {
    if (formikRef?.current?.dirty) {
      // Show confirmation dialog if there are unsaved changes
      setConfirmOpen(true);
    } else {
      onClose();
    }
  };

    // Handle close confirmation dialog
    const handleCancelClose = () => {
      setConfirmOpen(false);
    };
  
    // Handle confirm close action
    const handleConfirmClose = () => {
      setConfirmOpen(false);
      onClose();
    };

  /**
   * Render the forms on each step
   *
   * @function
   * @returns void
   */
  const renderStep = (step: number, formProps: any) => {
    switch (step) {
      case 0:
        return (
          <GeneralInformation
            formProps={formProps}
            clubsList={sortBy(clubsData, "name") || []}
            generalInformationData={formData}
          />
        );
      case 1:
        return (
          <Availability
            page={page}
            formProps={formProps}
            selectedClubId={formProps?.values?.clubs}
            selectedClubObj={clubsData?.find((club:any)=> club?._id === formProps?.values?.clubs)}
            clubsList={sortBy(clubsData, "name") || []}
            onJSONChange={handleAvailabilityChange}
            startDate={formProps?.values?.startDate}
            endDate={formProps?.values?.endDate}
          />
        );
      default:
        return null;
    }
  };

  return (
    <><Modal
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      slotProps={{
        backdrop: {
          style: {
            backgroundColor:
              action === ActionTypes?.CREATE
                ? "none"
                : page !== "tournamentDetail"
                ? "rgba(0,0,0,0.1)"
                : "rgba(0,0,0,0.5)",
          },
        },
      }}
    >
      <Slide in={open} direction="left">
        <Paper
          style={{
            position: "absolute",
            right: "0",
            transform: "translateY(-50%)",
            width: "36%",
            padding: "25px",
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Box>
            {/* Content of the sliding modal */}
            <BoldTypography variant="h6" style={{ marginBottom: "1rem" }}>
              {action === ActionTypes?.CREATE
                ? t("addNewTournament")
                : t("editTournament")}
            </BoldTypography>
            <Divider />
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseModal}
              aria-label="close"
              sx={{
                position: "absolute",
                top: "15px",
                right: "24px",
                cursor: "pointer",
              }}
            >
              <CloseIcon />
            </IconButton>
            <div>
              <p style={{ marginBottom: "20px" }}></p>
              <CustomStepper activeStep={activeStep} steps={steps} />
              <p style={{ marginBottom: "20px" }}></p>
              <Divider />
              <p style={{ marginBottom: "20px" }}></p>

              {activeStep < steps.length && (
                <Formik
                  innerRef={formikRef}
                  initialValues={
                    formData?._id
                      ? editData
                      : {
                          ...defaultTournamentValue,
                          clubs: formData?.club,
                          startDate: formData?.startDate,
                          courts: [
                            {
                              date: formData?.startDate || "",
                              court: formData?.court || "",
                              startTime: formData?.startTime || "",
                              endTime: formData?.endTime || "",
                              slots: isOverviewPage
                                ? calculateSlots(
                                    formData?.startDate,
                                    sortBy(clubsData, "name") || [],
                                    formData?.club,
                                    0
                                  )
                                : [],
                            },
                          ],
                        }
                  }
                  onSubmit={(values, actions) => {
                    handleNext(values, actions);
                  }}
                  enableReinitialize
                  validationSchema={AddTournamentValidationSchema(activeStep)}
                >
                  {(formProps: any) => {
                    return (
                      <>
                        <Form>
                          <div>
                            {/* Content for each step */}
                            {activeStep === 0 &&
                              renderStep(activeStep, formProps)}
                            {activeStep === 1 &&
                              renderStep(activeStep, formProps)}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Grid
                              container
                              spacing={2}
                              alignItems="center"
                              style={{ marginTop: "15px" }}
                            >
                              <Grid item xs={6}>
                                <Button
                                  disabled={activeStep === 0}
                                  onClick={handleBack}
                                >
                                  <ArrowBack /> {t("back")}
                                </Button>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                container
                                justifyContent="flex-end"
                              >
                                <SubmitButton
                                  type="submit"
                                  disabled={formProps?.isSubmitting}
                                >
                                  {activeStep === 0
                                    ? t("continueToAvailability")
                                    : formData?._id
                                    ? t("saveChanges")
                                    : t("saveNewTournament")}
                                </SubmitButton>
                              </Grid>
                            </Grid>
                          </div>
                        </Form>
                      </>
                    );
                  }}
                </Formik>
              )}
            </div>
          </Box>
        </Paper>
      </Slide>
    </Modal>
    
      {/* Confirmation dialog for unsaved changes */}
          <ConfirmDialog
        open={confirmOpen}
        onClose={handleCancelClose}
        onConfirm={handleConfirmClose}
      />
    </>
  );
};

export default AddTournamentModalForm;
